// 校验整数
const checkInteger = (value) => {
  if (!Number.isInteger(value)) {
    return '请输入整数';
  }
  return '';
};

// 校验整数
const checkSignlessInteger = (value) => {
  if (/^[1-9]\d*$/.test(value)) {
    return '';
  }
  return '请输入正整数';
};

// 校验数字区间
const checkRange = (value, {
  min,
  max,
}) => {
  if (min !== undefined && value < min) {
    return `请输入不小于${min}的数字`;
  }
  if (max !== undefined && value > max) {
    return `请输入不大于${max}的数字`;
  }
  return '';
};

// 校验非0正整数
const validateSignlessInteger = (rule, value, callback) => {
  const error = checkSignlessInteger(value);
  if (error) {
    callback(new Error(error));
    return;
  }

  callback();
};

// 校验多进深货架的进深数
const validateMultiDepth = (rule, value, callback) => {
  const error = checkInteger(value) || checkRange(value, { min: 3 });
  if (error) {
    callback(new Error(error));
    return;
  }

  callback();
};

export default {
  multiDepthRules: [
    { required: true, message: '请输入进深数', trigger: 'blur' },
    { validator: validateMultiDepth, trigger: 'blur' },
  ],
  containerOrder: [
    { required: true, message: '请输入库位顺序号', trigger: 'blur' },
    { validator: validateSignlessInteger, trigger: 'blur' },
  ],
  shelfOrder: [
    { required: true, message: '请输入上架顺序号', trigger: 'blur' },
    { validator: validateSignlessInteger, trigger: 'blur' },
  ],
  pickingOrder: [
    { required: true, message: '请输入拣货顺序号', trigger: 'blur' },
    { validator: validateSignlessInteger, trigger: 'blur' },
  ],
  maxBlockGoodsNum: [
    { required: true, message: '请输入最大板位数', trigger: 'blur' },
    { validator: validateSignlessInteger, trigger: 'blur' },
  ],
};
