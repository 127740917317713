import { FormType } from '@/constant/form';

export const detailFileds = [
  {
    label: '库位号',
    prop: 'storageLocationCode',
  },
  {
    label: '货架类型',
    prop: 'typeName',
  },
  {
    label: '所属库区',
    prop: 'warehouseAreaName',
  },
  {
    label: '巷道',
    prop: 'warehouseRow',
  },
  {
    label: '层',
    prop: 'layer',
  },
  {
    label: '货位列',
    prop: 'col',
  },
  {
    label: '限长(CM)',
    prop: 'warehouseLength',
  },
  {
    label: '限宽(CM)',
    prop: 'warehouseWidth',
  },
  {
    label: '限高(CM)',
    prop: 'warehouseHeight',
  },
  {
    label: '限重(KG)',
    prop: 'warehouseWeight',
  },
  {
    label: '是否记录容器',
    prop: 'trackOrderValue',
  },
  {
    label: '是否混放SKU',
    prop: 'blendedGoodsValue',
  },
  {
    label: '是否混放批次',
    prop: 'blendedBatchValue',
  },
  {
    label: '多货主混放',
    prop: 'blendedShipperValue',
  },
  {
    label: '库位顺序号',
    prop: 'containerOrder',
  },
  {
    label: '上架顺序号',
    prop: 'shelfOrder',
  },
  {
    label: '拣货顺序号',
    prop: 'pickingOrder',
  },
  {
    label: '库位状态',
    prop: 'statusName',
  },
  {
    label: '库位货品质量',
    prop: 'goodsQualityName',
    formatter: (info) => info.goodsQualityName.join('、'),
  },
  {
    label: '最大板位数',
    prop: 'maxBlockGoodsNum',
  },
];

const DICTIONARY = {
  START: '1',
  BOOTH: 'BOOTH', // 地摊位
};
const FEATURE = [{
  label: '已锁定',
  value: 1,
}];
const BLENDED_GOODS = [{
  label: '是 ',
  value: true,
}, {
  label: '否',
  value: false,
}];
const BLENDED_SHIPPER = [{
  label: '允许 ',
  value: true,
}, {
  label: '不允许 ',
  value: false,
}];
const TRACK_ORDER = [{
  label: '是 ',
  value: true,
}, {
  label: '否 ',
  value: false,
}];
const BLENDED_BATCH = [{
  label: '是 ',
  value: 1,
}, {
  label: '否 ',
  value: 0,
}];

const STATUS = [{
  label: '启用',
  value: '1',
}, {
  label: '禁用',
  value: '0',
}];

const STOREHOUSE = [{
  label: '拣货A区',
  value: '01',
}, {
  label: '拣货B区',
  value: '02',
}, {
  label: '拣货C区',
  value: '03',
}];
const TYPE = [{
  label: '拣货区',
  value: '01',
}, {
  label: '存储区',
  value: '02',
}, {
  label: '拣货暂存区',
  value: '03',
}];
const TABLECOLUMN = [
  {
    label: '序号',
    type: 'index',
    width: 50,
  }, {
    label: '库位号',
    prop: 'storageLocationCode',
    minWidth: 240,
  }, {
    label: '仓库编号',
    prop: 'warehouseCode',
    minWidth: 120,
  }, {
    label: '所属库区',
    prop: 'warehouseAreaName',
    minWidth: 120,
  }, {
    label: '货架类型',
    prop: 'typeCode',
    minWidth: 120,
  }, {
    label: '巷道号',
    prop: 'warehouseRow',
    minWidth: 120,
  }, {
    label: '是否跟踪容器',
    prop: 'trackOrderValue',
    minWidth: 120,
  }, {
    label: '是否混放SKU',
    prop: 'blendedGoodsValue',
    minWidth: 120,
  }, {
    label: '是否混放批次',
    prop: 'blendedBatchValue',
    minWidth: 120,
  }, {
    label: '多货主混放',
    prop: 'blendedShipperValue',
    minWidth: 120,
  }, {
    label: '商品band',
    prop: 'goodsBand',
    minWidth: 120,
  }, {
    label: '状态',
    prop: 'statusCode',
    formatter: (row) => (row.statusCode === DICTIONARY.START ? '是' : '否'),
  }, {
    label: '操作',
    prop: 'action',
    fixed: 'right',
    width: 240,
  }];
export {
  DICTIONARY,
  FEATURE,
  BLENDED_GOODS,
  BLENDED_SHIPPER,
  TRACK_ORDER,
  BLENDED_BATCH,
  TABLECOLUMN,
  STOREHOUSE,
  STATUS,
  TYPE,
};
export function serchFields(warehouseZonelist, warehouseZoneTypeCode) {
  return [
    {
      label: '库位',
      prop: 'storageLocationCode',
      component: FormType.INPUT,
    }, {
      label: '巷道号',
      prop: 'warehouseRow',
      component: FormType.INPUT,
    }, {
      label: '库区',
      prop: 'warehouseAreaId',
      component: FormType.SELECT,
      options: warehouseZonelist,
    }, {
      label: '库区类型',
      prop: 'warehouseZoneTypeCode',
      component: FormType.SELECT,
      options: warehouseZoneTypeCode,
    }, {
      label: '库位状态',
      prop: 'statusCode',
      component: FormType.SELECT,
      options: STATUS,
    },
  ];
}
