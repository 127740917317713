<template>
  <nh-drawer
    v-model="visible"
    title="批量新建"
    destroy-on-close
    size="1112px"
    :track="{
      name_zh: '基础数据/仓库相关/库位管理/抽屉-批量新建',
      trackName: $route.path
    }"
    @close="handleClose"
  >
    <div :loading="loading.handleCreate">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="所属库区" prop="warehouseAreaId">
              <nh-remote-select
                v-model="form.warehouseAreaId"
                :remote-method="getWarehouseZonelist"
                :loading="loading.getWarehouseZonelist"
              >
                <el-option
                  v-for="item in warehouseZonelist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </nh-remote-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="货架类型" prop="typeCode">
              <el-select
                v-model="form.typeCode"
                placeholder="--请选择--"
                @change="handleTypeCodeChange"
              >
                <el-option
                  v-for="(item, index) in STOREHOUSE_TYPE_CODE"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="巷道号"
              prop="warehouseRow"
            >
              <el-col :span="10">
                <el-input v-model.number="form.warehouseRow.start" />
              </el-col>
              <el-col :span="4" class="text-center">
                至
              </el-col>
              <el-col :span="10">
                <el-input v-model.number="form.warehouseRow.end" />
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="货位列"
              prop="col"
            >
              <el-col :span="10">
                <el-input v-model.number="form.col.start" />
              </el-col>
              <el-col :span="4" class="text-center">
                至
              </el-col>
              <el-col :span="10">
                <el-input v-model.number="form.col.end" />
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="层数"
              prop="layer"
              :rules="layerRules"
            >
              <el-col :span="10">
                <el-input
                  v-model.number="form.layer.start"
                  :disabled="disableLayer"
                />
              </el-col>
              <el-col :span="4" class="text-center">
                至
              </el-col>
              <el-col :span="10">
                <el-input
                  v-model.number="form.layer.end"
                  :disabled="disableLayer"
                />
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="进深数"
              prop="depth"
            >
              <el-input
                v-model.number="form.depth"
                :disabled="!enableEditDepth"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="限长宽高(cm)"
              prop="limit"
            >
              <el-row :gutter="5">
                <el-col :span="8">
                  <el-input v-model.number="form.limit.length" placeholder="长" />
                </el-col>
                <el-col :span="8">
                  <el-input v-model.number="form.limit.width" placeholder="宽" />
                </el-col>
                <el-col :span="8">
                  <el-input v-model.number="form.limit.height" placeholder="高" />
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="限重(KG)"
              prop="warehouseWeight"
            >
              <el-input v-model.number="form.warehouseWeight" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="是否跟踪容器"
              prop="trackOrder"
            >
              <el-radio-group
                v-model="form.trackOrder"
                size="mini"
              >
                <el-radio
                  v-for="(item, index) in TRACK_ORDER"
                  :key="index"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="是否混放SKU"
              prop="blendedGoods"
            >
              <el-radio-group
                v-model="form.blendedGoods"
                size="mini"
              >
                <el-radio
                  v-for="(item, index) in BLENDED_GOODS"
                  :key="index"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="是否混放批次"
              prop="blendedBatch"
            >
              <el-radio-group
                v-model="form.blendedBatch"
                size="mini"
              >
                <el-radio
                  v-for="(item, index) in BLENDED_BATCH"
                  :key="index"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="多货主混放"
              prop="blendedShipper"
            >
              <el-select
                v-model="form.blendedShipper"
                placeholder="--请选择--"
              >
                <el-option
                  v-for="(item, index) in BLENDED_SHIPPER"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="库位货品质量:" prop="goodsQualityCode">
              <el-checkbox-group v-model="form.goodsQualityCode" size="small">
                <el-checkbox
                  v-for="item in GOODS_QUALITY"
                  :key="item.value"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="最大板位数:" prop="maxBlockGoodsNum">
              <el-input v-model.number="form.maxBlockGoodsNum" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="buttons-in-content">
        <nh-button
          type="grey"
          @click="handlePreview('form')"
        >
          预 览
        </nh-button>
        <nh-button
          type="grey"
          @click="handleReset"
        >
          重 置
        </nh-button>
      </div>
    </div>
    <template #footer>
      <div class="footer">
        <nh-button
          type="grey"
          @click="close"
        >
          关 闭
        </nh-button>
        <nh-button
          :loading="loading.handleCreate"
          type="primary"
          @click="handleCreate"
        >
          确定创建
        </nh-button>
      </div>
    </template>
    <preview
      ref="preview"
      :list="list"
      :loading="loading.handleSave"
      @submit="handleSave"
    />
  </nh-drawer>
</template>
<script>
import loadingMixin from '@thales/loading';
import {
  validateNumberAndLetter,
} from '@/utils/validatorForm';
import { DepthType } from '../../constant';
import Preview from '../Preview/index.vue';
import mixin from '../../mixin';
import {
  DICTIONARY,
  BLENDED_GOODS,
  BLENDED_SHIPPER,
  TRACK_ORDER,
  BLENDED_BATCH,
} from '../../fileds';
import { storehouseGenerate, getPstorehouseBatchInsertdaPage } from '../../api';
import rules from '../../rules';

export default {
  name: 'StoreEdit',
  components: {
    Preview,
  },
  mixins: [loadingMixin, mixin],
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['createSuccess'],
  data() {
    const regMinus = (rule, value, callback) => {
      if (value < 0) {
        return callback(new Error('不能为负数'));
      }
      return callback();
    };

    // 校验非负整数
    const validateSignlessInteger = (value) => {
      if (!Number.isInteger(value)) {
        return '请输入整数';
      }

      if (value < 0) {
        return '不能为负数';
      }

      return null;
    };
    const validateRange = (rule, value, callback) => {
      const { start, end } = value;
      const error = validateSignlessInteger(start) || validateSignlessInteger(end);
      if (error) {
        callback(new Error(error));
        return;
      }

      if (start > end) {
        callback(new Error('开始值不能小于结束值'));
        return;
      }
      callback();
    };

    // 校验巷道号
    const validateWarehouseRow = (rule, value, callback) => {
      const { start, end } = value;
      if ((!start && typeof start !== 'number') || (!end && typeof end !== 'number')) {
        callback(new Error('请输入巷道号'));
        return;
      }
      const error = validateNumberAndLetter(start) || validateNumberAndLetter(end);

      if (error) {
        callback(new Error(error));
        return;
      }

      callback();
    };

    // 校验长宽高
    const validateLimit = (rule, value, callback) => {
      const { length, width, height } = value;
      if (length === null || width === null || height === null) {
        callback(new Error('请输入全部限制'));
        return;
      }

      if (length < 0 || width < 0 || height < 0) {
        callback(new Error('不能为负数'));
        return;
      }

      callback();
    };

    return {
      visible: false,
      DICTIONARY,
      BLENDED_GOODS,
      BLENDED_SHIPPER,
      TRACK_ORDER,
      BLENDED_BATCH,
      form: {
        warehouseAreaId: null,
        typeCode: null,
        col: {
          start: null,
          end: null,
        },
        layer: {
          start: null,
          end: null,
        },
        warehouseRow: {
          start: null,
          end: null,
        },
        limit: {
          length: null,
          width: null,
          height: null,
        },
        warehouseWeight: null,
        trackOrder: null,
        blendedGoods: null,
        blendedBatch: null,
        blendedShipper: null,
        depth: null,
        goodsQualityCode: [],
        maxBlockGoodsNum: 1, // 默认值为1
      },
      loading: {
        handleCreate: false,
        getWarehouseZonelist: false,
      },
      layerRules: [
        { required: true, message: '请输入层数', trigger: 'blur' },
        { validator: validateRange, trigger: 'blur' },
      ],
      rules: {
        depth: [],
        limit: [
          { required: true, message: '请输入货位排', trigger: 'blur' },
          { validator: validateLimit, trigger: 'blur' },
        ],
        col: [
          { required: true, message: '请输入货位排', trigger: 'blur' },
          { validator: validateRange, trigger: 'blur' },
        ],
        warehouseRow: [
          { required: true, message: '请输入巷道号', trigger: 'blur' },
          { validator: validateWarehouseRow, trigger: 'blur' },
        ],
        warehouseAreaId: [
          { required: true, message: '请选择库区', trigger: 'change' },
        ],
        typeCode: [
          { required: true, message: '请选择货架类型', trigger: 'change' },
        ],
        warehouseLength: [
          { required: true, message: '请输入限长', trigger: 'blur' },
          { type: 'number', message: '必须为数字值', trigger: 'blur' },
          { validator: regMinus, trigger: 'blur' },
        ],
        warehouseWidth: [
          { required: true, message: '请输入限宽', trigger: 'blur' },
          { type: 'number', message: '必须为数字值', trigger: 'blur' },
          { validator: regMinus, trigger: 'blur' },
        ],
        warehouseHeight: [
          { required: true, message: '请输入限高', trigger: 'blur' },
          { type: 'number', message: '必须为数字值', trigger: 'blur' },
          { validator: regMinus, trigger: 'blur' },
        ],
        warehouseWeight: [
          { required: true, message: '请输入限重', trigger: 'blur' },
          { type: 'number', message: '必须为数字值', trigger: 'blur' },
          { validator: regMinus, trigger: 'blur' },
        ],
        trackOrder: [
          { required: true, message: '请选择是否跟踪容器', trigger: 'change' },
        ],
        blendedGoods: [
          { required: true, message: '请选择是否混放SKU', trigger: 'change' },
        ],
        blendedBatch: [
          { required: true, message: '请选择是否混放批次', trigger: 'change' },
        ],
        blendedShipper: [
          { required: true, message: '请选择货主混放', trigger: 'change' },
        ],
        goodsQualityCode: [
          { required: true, message: '请选择存放货品质量', trigger: 'change' },
        ],
        maxBlockGoodsNum: rules.maxBlockGoodsNum,
      },
      list: [],
    };
  },
  computed: {
    enableEditDepth() {
      // 多进深货架才可编辑进深
      return this.form.typeCode === DepthType.MULTI_DEPTH_SHELF;
    },
    disableLayer() {
      // 地摊货架不可编辑层数
      return this.form.typeCode === DICTIONARY.BOOTH;
    },
  },
  watch: {
    disableLayer(disabled) {
      if (disabled) {
        // 如果层数不可编辑，清空校验
        this.$refs.form.clearValidate('layer');
        this.form.layer.start = 1;
        this.form.layer.end = 1;
      }
    },
    'form.warehouseAreaId': {
      handler(val) {
        const findArr = this.warehouseZonelist.find((item) => item.value === val);
        this.form.warehouseZoneTypeCode = findArr ? findArr.typeCode : null;
        this.form.warehouseAreaName = findArr ? findArr.label : null;
      },
    },
    'form.typeCode': {
      handler(val) {
        if (val === DICTIONARY.BOOTH) {
          this.form.layer.start = 1;
          this.form.layer.end = 1;
        }
      },
    },
  },
  methods: {
    // 直接生成库位：
    async handleCreate() {
      // 1、先通过api批量生成数据
      await this.generateStorehouse();
      // 2、将批量数据保存到数据库
      await this.save();
      this.close();
    },
    // 预览生成的数据
    async handlePreview() {
      await this.generateStorehouse();
      this.$refs.preview.open();
    },
    // 生成数据
    async generateStorehouse() {
      this.list = [];
      await this.$refs.form.validate();
      const params = this.getCreatParams();
      const resp = await storehouseGenerate({ ...params, statusCode: 1, statusName: '启用' });
      this.list = resp.map(((item) => ({
        ...item,
        typeName: this.getLabel(item.typeCode, this.STOREHOUSE_TYPE_CODE),
      })));
    },
    // 将批量生成的数据保存到数据库
    async handleSave() {
      await this.save();
      this.close();
    },
    async save() {
      await getPstorehouseBatchInsertdaPage({ warehouseStorehouseReqs: this.list });
      this.$message({
        type: 'success',
        message: '添加成功',
      });
      this.$emit('createSuccess');
    },
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    getLabel(val, arr) {
      const findArr = arr.find((item) => item.value === val);
      return findArr ? findArr.label : '';
    },
    handleReset() {
      this.resetForm();
    },
    resetForm() {
      this.$refs.form.resetFields();
      this.form.col.start = null;
      this.form.col.end = null;
      this.form.layer.start = null;
      this.form.layer.end = null;
      this.form.warehouseRow.start = null;
      this.form.warehouseRow.end = null;
      this.form.limit.length = null;
      this.form.limit.width = null;
      this.form.limit.height = null;
    },
    // 货架类型改变
    handleTypeCodeChange() {
      // 更新进深数相关信息
      this.updateDepth();
    },
    updateDepth() {
      this.updateDepthRules();
      this.updateDepthValue();
    },
    updateDepthRules() {
      if (this.form.typeCode === DepthType.MULTI_DEPTH_SHELF) {
        // 如果货架类型为多进深，进深数必填
        this.rules.depth = rules.multiDepthRules;
        return;
      }

      this.rules.depth = [];
    },
    updateDepthValue() {
      if (this.form.typeCode === DepthType.DOUBLE_DEPTH_SHELF) {
        // 如果货架类型为双进深，进深数为2
        this.form.depth = 2;
        return;
      }

      this.form.depth = null;
    },
    getCreatParams() {
      const {
        col, layer, warehouseRow, limit, ...otherParams
      } = this.form;
      return {
        ...otherParams,
        col: col.start,
        colEnd: col.end,
        layer: layer.start,
        layerEnd: layer.end,
        warehouseRow: warehouseRow.start,
        warehouseRowEnd: warehouseRow.end,
        warehouseLength: limit.height,
        warehouseWidth: limit.width,
        warehouseHeight: limit.height,

      };
    },
    handleClose() {
      this.resetForm();
      this.$refs.preview.close();
    },
  },
};
</script>
<style lang="scss" scoped>

.buttons-in-content {
  margin-left: 146px;
}

.text-center {
  text-align: center;
}

.header-component {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;

  .form-item {
    flex: 1 1 percentage(1 / 3);
    padding: 0;
  }
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.table-input {
  display: flex;
  align-items: center;

  .el-form-item {
    margin-bottom: 0;
  }

  .el-input-number {
    width: 60px;
  }
}

.width-100 {
  width: 100%;
}

.footer {
  text-align: right;
  padding: 0 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;

  .flex_row {
    display: flex;
    flex-direction: row;
  }

  .field {
    padding-right: 30px;

    &:last-child {
      padding-left: 50px;
    }
  }
}
</style>
<style lang="scss">
.width-100 {
  .table-date {
    width: 100%;
  }
}
</style>
