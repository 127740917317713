<template>
  <nh-dialog
    title="批量新建"
    :model-value="visible"
    :before-close="handleClose"
  >
    <div :loading="loading.handleSubmit">
      <nh-dialog-form
        ref="editForm"
        :model="editForm"
        :rules="rules"
      >
        <nh-dialog-form-item label="所属库区" prop="warehouseAreaId">
          <nh-remote-select
            v-model="editForm.warehouseAreaId"
            :remote-method="getWarehouseZonelist"
            :loading="loading.getWarehouseZonelist"
          >
            <el-option
              v-for="item in warehouseZonelist"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </nh-remote-select>
        </nh-dialog-form-item>
        <nh-dialog-form-item label="货架类型" prop="typeCode">
          <el-select
            v-model="editForm.typeCode"
            placeholder="--请选择--"
            @change="handleTypeCodeChange"
          >
            <el-option
              v-for="(item, index) in STOREHOUSE_TYPE_CODE"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="巷道号"
          prop="warehouseRow"
        >
          <el-input v-model.number="editForm.warehouseRow" />
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="至"
          prop="warehouseRowEnd"
        >
          <el-input v-model.number="editForm.warehouseRowEnd" />
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="层数"
          prop="layer"
        >
          <el-input
            v-model.number="editForm.layer"
            :disabled="editForm.typeCode === DICTIONARY.BOOTH"
          />
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="至"
          prop="layerEnd"
        >
          <el-input
            v-model.number="editForm.layerEnd"
            :disabled="editForm.typeCode === DICTIONARY.BOOTH"
          />
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="货位列"
          prop="col"
        >
          <el-input v-model.number="editForm.col" />
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="至"
          prop="colEnd"
        >
          <el-input v-model.number="editForm.colEnd" />
        </nh-dialog-form-item>
        <nh-dialog-form-item label="进深数:" prop="depth">
          <el-input
            v-model.number="editForm.depth"
            :disabled="!enableEditDepth"
          />
        </nh-dialog-form-item>
        <nh-dialog-form-item label="限长(CM):" prop="warehouseLength">
          <el-input v-model.number="editForm.warehouseLength" />
        </nh-dialog-form-item>
        <nh-dialog-form-item label="限宽(CM):" prop="warehouseWidth">
          <el-input v-model.number="editForm.warehouseWidth" />
        </nh-dialog-form-item>
        <nh-dialog-form-item label="限高(CM):" prop="warehouseHeight">
          <el-input v-model.number="editForm.warehouseHeight" />
        </nh-dialog-form-item>
        <nh-dialog-form-item label="限重(KG):" prop="warehouseWeight">
          <el-input v-model.number="editForm.warehouseWeight" />
        </nh-dialog-form-item>
        <nh-dialog-form-item label="是否跟踪容器:" prop="trackOrder">
          <el-select
            v-model="editForm.trackOrder"
            placeholder="--请选择--"
          >
            <el-option
              v-for="(item, index) in TRACK_ORDER"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </nh-dialog-form-item>
        <nh-dialog-form-item label="是否混放SKU:" prop="blendedGoods">
          <el-select
            v-model="editForm.blendedGoods"
            placeholder="--请选择--"
          >
            <el-option
              v-for="(item) in BLENDED_GOODS"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </nh-dialog-form-item>
        <nh-dialog-form-item label="是否混放批次:" prop="blendedBatch">
          <el-select
            v-model="editForm.blendedBatch"
            placeholder="--请选择--"
          >
            <el-option
              v-for="(item, index) in BLENDED_BATCH"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </nh-dialog-form-item>
        <nh-dialog-form-item label="多货主混放:" prop="blendedShipper">
          <el-select
            v-model="editForm.blendedShipper"
            placeholder="--请选择--"
          >
            <el-option
              v-for="(item, index) in BLENDED_SHIPPER"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </nh-dialog-form-item>
        <nh-dialog-form-item label="库位货品质量:" prop="goodsQualityCode">
          <el-checkbox-group v-model="editForm.goodsQualityCode" size="small">
            <el-checkbox
              v-for="item in GOODS_QUALITY"
              :key="item.value"
              :label="item.value"
            >
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </nh-dialog-form-item>
        <nh-dialog-form-item label="最大板位数:" prop="maxBlockGoodsNum">
          <el-input v-model.number="editForm.maxBlockGoodsNum" />
        </nh-dialog-form-item>
      </nh-dialog-form>
    </div>
    <template #footer>
      <div>
        <nh-button
          :loading="loading.handleSubmit"
          type="primary"
          @click="handleSubmit('editForm')"
        >
          预 览
        </nh-button>
        <nh-button
          @click="handleReset('editForm')"
        >
          重 置
        </nh-button>
        <nh-button
          type="grey"
          @click="handleClose"
        >
          关 闭
        </nh-button>
      </div>
    </template>
    <List
      v-if="visibleList"
      :list="list"
      :visible="visibleList"
      @handlePreviewListVisible="handlePreviewListVisible"
    />
  </nh-dialog>
</template>
<script>
import loadingMixin from '@thales/loading';
import {
  validateNumberAndLetter,
} from '@/utils/validatorForm';
import { DepthType } from '../constant';
import List from './previewList.vue';
import mixin from '../mixin';
import {
  DICTIONARY,
  BLENDED_GOODS,
  BLENDED_SHIPPER,
  TRACK_ORDER,
  BLENDED_BATCH,
} from '../fileds';
import { storehouseGenerate } from '../api';

export default {
  name: 'StoreEdit',
  components: {
    List,
  },
  mixins: [loadingMixin, mixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['handleAddVisible'],
  data() {
    const validateStart = (name, rule, value, callback) => {
      if (!Number.isInteger(value)) {
        return callback(new Error('请输入数字值'));
      }
      if (value < 0) {
        return callback(new Error('不能为负数'));
      }
      if (this.editForm[name]) {
        this.$refs.editForm.validateField(name);
      }
      return callback();
    };
    const validateEnd = (name, rule, value, callback) => {
      if (!Number.isInteger(value)) {
        return callback(new Error('请输入数字值'));
      }
      if (this.editForm[name] > value) {
        return callback(new Error('不能小于最小值'));
      }
      return callback();
    };
    const regMinus = (rule, value, callback) => {
      if (value < 0) {
        return callback(new Error('不能为负数'));
      }
      return callback();
    };
    return {
      DICTIONARY,
      BLENDED_GOODS,
      BLENDED_SHIPPER,
      TRACK_ORDER,
      BLENDED_BATCH,
      editForm: {
        warehouseAreaId: null,
        typeCode: null,
        col: null,
        colEnd: null,
        layer: null,
        layerEnd: null,
        warehouseRow: null,
        warehouseRowEnd: null,
        warehouseLength: null,
        warehouseWidth: null,
        warehouseHeight: null,
        warehouseWeight: null,
        trackOrder: null,
        blendedGoods: null,
        blendedBatch: null,
        blendedShipper: null,
        depth: null,
        goodsQualityCode: [],
        maxBlockGoodsNum: 1, // 默认值为1
      },
      loading: {
        handleSubmit: false,
        getWarehouseZonelist: false,
      },
      rules: {
        col: [
          { required: true, message: '请输入货位排', trigger: 'blur' },
          { validator: ((rule, value, callback) => validateStart('colEnd', rule, value, callback)), trigger: 'blur' },
        ],
        colEnd: [
          { required: true, message: '请输入货位排', trigger: 'blur' },
          { validator: ((rule, value, callback) => validateEnd('col', rule, value, callback)), trigger: 'blur' },
        ],
        layer: [
          { required: true, message: '请输入层数', trigger: 'blur' },
          { validator: ((rule, value, callback) => validateStart('layerEnd', rule, value, callback)), trigger: 'blur' },
        ],
        layerEnd: [
          { required: true, message: '请输入层数', trigger: 'blur' },
          { validator: ((rule, value, callback) => validateEnd('layer', rule, value, callback)), trigger: 'blur' },
        ],
        warehouseRow: [
          { required: true, message: '请输入巷道号', trigger: 'blur' },
          { validator: ((rule, value, callback) => validateNumberAndLetter(value, callback)), trigger: 'blur' },
        ],
        warehouseRowEnd: [
          { required: true, message: '请输入巷道号', trigger: 'blur' },
          { validator: ((rule, value, callback) => validateEnd('warehouseRow', rule, value, callback)), trigger: 'blur' },
        ],
        warehouseAreaId: [
          { required: true, message: '请选择库区', trigger: 'change' },
        ],
        typeCode: [
          { required: true, message: '请选择货架类型', trigger: 'change' },
        ],
        warehouseLength: [
          { required: true, message: '请输入限长', trigger: 'blur' },
          { type: 'number', message: '必须为数字值', trigger: 'blur' },
          { validator: regMinus, trigger: 'blur' },
        ],
        warehouseWidth: [
          { required: true, message: '请输入限宽', trigger: 'blur' },
          { type: 'number', message: '必须为数字值', trigger: 'blur' },
          { validator: regMinus, trigger: 'blur' },
        ],
        warehouseHeight: [
          { required: true, message: '请输入限高', trigger: 'blur' },
          { type: 'number', message: '必须为数字值', trigger: 'blur' },
          { validator: regMinus, trigger: 'blur' },
        ],
        warehouseWeight: [
          { required: true, message: '请输入限重', trigger: 'blur' },
          { type: 'number', message: '必须为数字值', trigger: 'blur' },
          { validator: regMinus, trigger: 'blur' },
        ],
        trackOrder: [
          { required: true, message: '请选择是否跟踪容器', trigger: 'change' },
        ],
        blendedGoods: [
          { required: true, message: '请选择是否混放SKU', trigger: 'change' },
        ],
        blendedBatch: [
          { required: true, message: '请选择是否混放批次', trigger: 'change' },
        ],
        blendedShipper: [
          { required: true, message: '请选择货主混放', trigger: 'change' },
        ],
        goodsQualityCode: [
          { required: true, message: '请选择存放货品质量', trigger: 'change' },
        ],
        maxBlockGoodsNum: [
          { required: true, message: '请输入最大板位数', trigger: 'blur' },
          { type: 'number', message: '必须为数字值', trigger: 'blur' },
        ],
      },
      list: [],
      visibleList: false,
    };
  },
  computed: {
    enableEditDepth() {
      return this.editForm.typeCode === DepthType.MULTI_DEPTH_SHELF;
    },
  },
  watch: {
    'editForm.warehouseAreaId': {
      handler(val) {
        const findArr = this.warehouseZonelist.find((item) => item.value === val);
        this.editForm.warehouseZoneTypeCode = findArr ? findArr.typeCode : null;
        this.editForm.warehouseAreaName = findArr ? findArr.label : null;
      },
    },
    'editForm.typeCode': {
      handler(val) {
        if (val === DICTIONARY.BOOTH) {
          this.editForm.layer = 1;
          this.editForm.layerEnd = 1;
        }
      },
    },
  },
  methods: {
    getLabel(val, arr) {
      const findArr = arr.find((item) => item.value === val);
      return findArr ? findArr.label : '';
    },
    handleClose() {
      this.handleReset('editForm');
      this.$emit('handleAddVisible');
    },
    async handleSubmit() {
      this.list = [];
      await this.$refs.editForm.validate();
      const resp = await storehouseGenerate({ ...this.editForm, statusCode: 1, statusName: '启用' });
      this.list = resp.map(((item) => ({
        ...item,
        typeName: this.getLabel(item.typeCode, this.STOREHOUSE_TYPE_CODE),
      })));
      this.visibleList = true;
    },
    handleReset(formName) {
      this.$refs[formName].resetFields();
    },
    handlePreviewListVisible() {
      this.handleClose();
    },
    // 货架类型改变
    handleTypeCodeChange() {
      // 计算进深数
      this.updateDeoth();
    },
    updateDeoth() {
      if (this.editForm.typeCode === DepthType.DOUBLE_DEPTH_SHELF) {
        // 如果货架类型为双进深，进深数为2
        this.editForm.depth = 2;
        return;
      }

      this.editForm.depth = null;
    },
  },
};
</script>
