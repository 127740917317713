<template>
  <pl-block>
    <PlForm
      v-model="searchForm"
      mode="search"
      :fields="serchFields(warehouseZonelist, ZONE_TYPE_CODE)"
      @submit="handleSearch"
      @reset="handleSearch"
    />
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getReservoirList"
      :data="reservoirList"
      :show-table-setting="true"
      :columns="TABLECOLUMN"
    >
      <template #tableHeaderRight>
        <nh-button
          :track="{
            trackName: '基础数据/仓库相关/库位管理/[批量新建]'
          }"
          @click="handleBatchAdd"
        >
          批量新建
        </nh-button>
        <nh-button
          :track="{
            trackName: '基础数据/仓库相关/库位管理/[导入]'
          }"
          @click="importStore"
        >
          导入
        </nh-button>
        <nh-button
          :track="{
            trackName: '基础数据/仓库相关/库位管理/[导出]'
          }"
          @click="exportStore"
        >
          导出
        </nh-button>
        <nh-button
          :track="{
            trackName: '基础数据/仓库相关/库位管理/[打印标签]'
          }"
          @click="print"
        >
          打印标签
        </nh-button>
      </template>
      <template #typeCode="{row}">
        <span>
          {{ filterLabel(row.typeCode, STOREHOUSE_TYPE_CODE) }}</span>
      </template>
      <template #goodsBand="{row}">
        <span>
          {{ filterLabel(row.goodsBand, PRODUCT_BAND_CODE) }}</span>
      </template>
      <template #action="{row}">
        <nh-button type="text" @click="handleStoreView(row)">
          查看
        </nh-button>
        <nh-button type="text" @click="handleStoreEdit(row)">
          编辑
        </nh-button>
        <nh-button type="text" @click="handleEnable(row)">
          {{ row.statusCode === DICTIONARY.START ? "禁用" : "启用" }}
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <Add
    v-if="addVisible"
    :visible="addVisible"
    @createSuccess="handleAddVisible"
  />
  <Edit
    ref="storeEdit"
    :visible="editVisible"
    @handleEditVisible="handleEditVisible"
    @editSuccess="getReservoirList"
  />
  <Preview
    ref="goodsband"
  />
  <BatchAdd ref="batchAdd" />
  <nh-data-import
    ref="DataImport"
    :err-msg-list="errMsgList"
    :is-upload="isUpload"
    :track="{
      name_zh: '基础数据/仓库相关/库位管理/弹窗-导入',
      trackName: $route.path
    }"
    @upload="warehouseGoodsImport"
    @downLoadTemplate="handleDownTemplate"
    @submitSuccess="handleGoodsImportSubmitSuccess"
    @cancel="handleGoodsImportSubmitSuccess"
    @clearAll="handleGoodsImportClearAll"
  />
</template>
<script>
import loadingMixin from '@thales/loading';
import Add from './components/add.vue';
import Edit from './components/edit.vue';
import Preview from './components/preview.vue';
import BatchAdd from './subpages/BatchAdd/index.vue';
import mixin from './mixin';
import {
  storehousePage, storehouseEnable, exportStorehouse, uploadStation, downLoadTemplate,
} from './api';
import {
  DICTIONARY, TABLECOLUMN, STOREHOUSE, STATUS, serchFields,
} from './fileds';

export default {
  name: 'Storehouse',
  components: {
    BatchAdd,
    Add,
    Edit,
    Preview,
  },
  mixins: [loadingMixin, mixin],

  data() {
    return {
      serchFields,
      DICTIONARY,
      TABLECOLUMN,
      STOREHOUSE,
      STATUS,
      reservoirList: [],
      searchForm: {},
      addVisible: false,
      editVisible: false,
      editid: '',
      warehouseZonelist: [],
      loading: {
        getReservoirList: false,
      },
      downURL: '/hope-saas-base-web/excel/station_import.xlsx',
      isUpload: false,
      errMsgList: [],
    };
  },
  computed: {
    STOREHOUSE_TYPE_CODE() {
      return this.$dictionaryFiledMap.STOREHOUSE_TYPE_CODE || [];
    },
  },
  watch: {
    pagination() {
      this.getReservoirList();
    },
  },
  created() {
    this.getReservoirList();
  },
  methods: {
    handleBatchAdd() {
      this.$refs.batchAdd.open();
    },
    getLabel(val, arr) {
      const findArr = arr.find((item) => item.value === val);
      return findArr ? findArr.label : '';
    },
    handleSearch() {
      this.pagination.page = 1;
      this.getReservoirList();
    },
    async getReservoirList() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await storehousePage(this.searchForm, pagination);
      this.reservoirList = resp.records;
      this.pagination.total = resp.total;
    },
    handleAddVisible() {
      this.getReservoirList();
    },
    addReservoir() {
      this.addVisible = true;
    },
    handleStoreEdit(row) {
      this.editVisible = true;
      this.$refs.storeEdit.init(row);
    },
    handleEditVisible() {
      this.editVisible = false;
    },
    handleStoreView(row) {
      const data = {
        ...row,
        typeName: this.getLabel(row.typeCode, this.STOREHOUSE_TYPE_CODE),
      };
      this.$refs.goodsband.open(data);
    },
    async handleEnable(row) {
      await this.$confirm(
        `你确定要${row.statusCode === DICTIONARY.START ? '禁用' : '启用'}该库位?`,
        {
          confirmButtonText: '确定',
          cancelButtonText: '关闭',
        },
      );
      const params = {
        id: row.id,
        statusEnum: row.statusCode === DICTIONARY.START ? 'DISABLED' : 'ENABLED',
      };
      await storehouseEnable(params, {});
      await this.getReservoirList();
      this.$message({
        type: 'success',
        message: '操作成功!',
      });
    },
    // 导入
    importStore() {
      this.$refs.DataImport.showDialog();
    },
    exportStore() {
      exportStorehouse(this.searchForm);
    },
    filterLabel(val, arr) {
      const filterArr = arr.find((item) => item.value === val);
      return filterArr ? filterArr.label : '';
    },
    // 上传接口
    async warehouseGoodsImport(fd) {
      const errorMsg = await uploadStation(fd);
      this.isUpload = true;
      this.errMsgList = [...this.errMsgList, ...errorMsg.map((item) => ({ errorMsg: item }))];
    },
    // 模板下载接口
    async handleDownTemplate() {
      await downLoadTemplate({ templateId: 'location_import_template_id' });
    },
    handleGoodsImportSubmitSuccess() {
      this.handleGoodsImportClearAll();
    },
    handleGoodsImportClearAll() {
      this.isUpload = false;
      this.errMsgList = [];
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
