import {
  warehouseZonePage,
} from './api';

export default {
  data() {
    return {
      warehouseZonelist: [],
    };
  },
  computed: {
    ZONE_TYPE_CODE() {
      // 通过 dictionaryFiledMap 获取数据字典中的 ZONE_TYPE_CODE ，返回格式[{value: code, label: name}]
      return this.$dictionaryFiledMap.ZONE_TYPE_CODE || [];
    },
    STOREHOUSE_TYPE_CODE() {
      return this.$dictionaryFiledMap.STOREHOUSE_TYPE_CODE || [];
    },
    PRODUCT_BAND_CODE() {
      return this.$dictionaryFiledMap.PRODUCT_BAND_CODE || [];
    },
    GOODS_QUALITY() {
      return this.$dictionaryFiledMap.GOODS_QUALITY || [];
    },
  },
  mounted() {
    this.getWarehouseZonelist();
  },
  methods: {
    async getWarehouseZonelist(word) {
      const resp = await warehouseZonePage({ page: 1, size: 1000 }, { zoneName: word || '' });
      this.warehouseZonelist = resp.records.map((item) => ({
        label: item.zoneName,
        value: item.id,
      }));
    },
  },
};
