<template>
  <nh-dialog
    :model-value="visible"
    title="编辑"
    :before-close="handleClose"
    :track="{
      name_zh: '基础数据/仓库相关/库位管理/弹窗-编辑',
      trackName: $route.path
    }"
  >
    <nh-dialog-form
      ref="editForm"
      :model="editForm"
      :rules="rules"
    >
      <nh-dialog-form-item label="库位号" prop="storageLocationCode">
        <el-input v-model="editForm.storageLocationCode" disabled />
      </nh-dialog-form-item>
      <nh-dialog-form-item label="货架类型" prop="typeCode">
        <el-select
          v-model="editForm.typeCode"
          disabled
          placeholder="--请选择--"
        >
          <el-option
            v-for="(item, index) in STOREHOUSE_TYPE_CODE"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </nh-dialog-form-item>
      <nh-dialog-form-item label="所属库区" prop="warehouseAreaName">
        <el-select
          v-model="editForm.warehouseAreaName"
          disabled
          placeholder="--请选择--"
        >
          <el-option
            v-for="(item, index) in warehouseZonelist"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </nh-dialog-form-item>
      <nh-dialog-form-item label="巷道号" prop="warehouseRow">
        <el-input v-model="editForm.warehouseRow" disabled />
      </nh-dialog-form-item>
      <nh-dialog-form-item label="是否跟踪容器:" prop="trackOrder">
        <el-select
          v-model="editForm.trackOrder"
          placeholder="--请选择--"
        >
          <el-option
            v-for="(item, index) in TRACK_ORDER"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </nh-dialog-form-item>
      <nh-dialog-form-item label="是否混放SKU:" prop="blendedGoods">
        <el-select
          v-model="editForm.blendedGoods"
          placeholder="--请选择--"
        >
          <el-option
            v-for="(item, index) in BLENDED_GOODS"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </nh-dialog-form-item>
      <nh-dialog-form-item label="是否混放批次:" prop="blendedBatch">
        <el-select
          v-model="editForm.blendedBatch"
          placeholder="--请选择--"
        >
          <el-option
            v-for="(item, index) in BLENDED_BATCH"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </nh-dialog-form-item>
      <nh-dialog-form-item label="多货主混放:" prop="blendedShipper">
        <el-select
          v-model="editForm.blendedShipper"
          placeholder="--请选择--"
        >
          <el-option
            v-for="(item, index) in BLENDED_SHIPPER"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </nh-dialog-form-item>
      <nh-dialog-form-item label="库位顺序号:" prop="containerOrder">
        <el-input v-model="editForm.containerOrder" />
      </nh-dialog-form-item>
      <nh-dialog-form-item label="上架顺序号:" prop="shelfOrder">
        <el-input v-model="editForm.shelfOrder" />
      </nh-dialog-form-item>
      <nh-dialog-form-item label="拣货顺序号:" prop="pickingOrder">
        <el-input v-model="editForm.pickingOrder" />
      </nh-dialog-form-item>
      <nh-dialog-form-item label="商品band:" prop="goodsBand">
        <el-select
          v-model="editForm.goodsBand"
          placeholder="--请选择--"
        >
          <el-option
            v-for="(item, index) in PRODUCT_BAND_CODE"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </nh-dialog-form-item>
      <nh-dialog-form-item label="库位货品质量:" prop="goodsQualityCode">
        <el-checkbox-group v-model="editForm.goodsQualityCode" size="small">
          <el-checkbox
            v-for="item in GOODS_QUALITY"
            :key="item.value"
            :label="item.value"
          >
            {{ item.label }}
          </el-checkbox>
        </el-checkbox-group>
      </nh-dialog-form-item>
      <nh-dialog-form-item label="最大板位数:" prop="maxBlockGoodsNum">
        <el-input v-model.number="editForm.maxBlockGoodsNum" />
      </nh-dialog-form-item>
    </nh-dialog-form>
    <template #footer>
      <div>
        <nh-button
          type="primary"
          :loading="loading.handleSubmit"
          @click="handleSubmit('editForm')"
        >
          确  认
        </nh-button>
        <nh-button
          type="grey"
          @click="handleClose"
        >
          关 闭
        </nh-button>
      </div>
    </template>
  </nh-dialog>
</template>
<script>
import {
  BLENDED_GOODS,
  BLENDED_SHIPPER,
  TRACK_ORDER,
  BLENDED_BATCH,
} from '../fileds';
import { storehouseUpdate } from '../api';
import mixin from '../mixin';
import rules from '../rules';

export default {
  name: 'StoreEdit',
  mixins: [mixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['handleEditVisible', 'editSuccess'],
  data() {
    return {
      BLENDED_GOODS,
      BLENDED_SHIPPER,
      TRACK_ORDER,
      BLENDED_BATCH,
      editForm: {
      },
      rules: {
        trackOrder: [
          { required: true, message: '请选择是否跟踪容器', trigger: 'change' },
        ],
        containerOrder: rules.containerOrder,
        shelfOrder: rules.shelfOrder,
        pickingOrder: rules.pickingOrder,
        blendedGoods: [
          { required: true, message: '请选择是否混放SKU', trigger: 'change' },
        ],
        blendedBatch: [
          { required: true, message: '请选择是否混放批次', trigger: 'change' },
        ],
        blendedShipper: [
          { required: true, message: '请选择货主混放', trigger: 'change' },
        ],
        goodsQualityCode: [
          { required: true, message: '请选择存放货品质量', trigger: 'change' },
        ],
        maxBlockGoodsNum: rules.maxBlockGoodsNum,
      },
      loading: {
        handleSubmit: false,
      },
    };
  },
  methods: {
    init(data) {
      if (data) {
        this.editForm = { ...data };
      }
    },
    handleClose() {
      this.$emit('handleEditVisible');
    },
    async handleSubmit(formName) {
      await this.$refs[formName].validate();
      await storehouseUpdate(this.editForm);
      this.$message({
        type: 'success',
        message: '修改成功!',
      });
      this.$emit('editSuccess');
      this.handleClose('editForm');
    },
  },
};
</script>
