import { TRACK_ORDER, BLENDED_GOODS, BLENDED_BATCH } from '../../fileds';

const TABLECOLUMN = [{
  label: '库位号',
  prop: 'storageLocationCode',
  width: 240,
}, {
  label: '所属库区',
  prop: 'warehouseAreaName',
  width: 180,
}, {
  label: '货架类型',
  prop: 'typeName',
  width: 180,
}, {
  label: '巷道号',
  prop: 'warehouseRow',
  width: 180,
}, {
  label: '货位列',
  prop: 'col',
  width: 180,
}, {
  label: '层数',
  prop: 'layer',
  width: 180,
}, {
  label: '是否跟踪LPN',
  prop: 'trackOrder',
  width: 180,
  filter: true,
  arr: TRACK_ORDER,
}, {
  label: '是否混入SUK',
  prop: 'blendedGoods',
  width: 180,
  filter: true,
  arr: BLENDED_GOODS,
}, {
  label: '是否混放批次',
  prop: 'blendedBatch',
  width: 180,
  filter: true,
  arr: BLENDED_BATCH,
}, {
  label: '库位顺序号',
  prop: 'containerOrder',
  width: 180,
},
// {
//   label: '上架顺序号',
//   prop: 'shelfOrder',
// },
// {
//   label: '拣货顺序号',
//   prop: 'pickingOrder',
// },
{
  label: '商品band',
  prop: 'goodsBand',
  width: 180,
},
];

export default TABLECOLUMN;
