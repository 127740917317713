<template>
  <nh-dialog
    v-model="visible"
    title="查看详情"
    append-to-body
    :track="{
      name_zh: '基础数据/仓库相关/库位管理/弹窗-查看',
      trackName: $route.path
    }"
    @close="handleClose"
  >
    <nh-dialog-form
      ref="ruleForm"
      :model="form"
      :rules="rules"
    >
      <nh-dialog-form-item
        v-for="(item) in detailFileds"
        :key="item.prop"
        :label="`${item.label}：`"
      >
        {{ item.formatter ? item.formatter(previewValue) : previewValue[item.prop] }}
      </nh-dialog-form-item>
    </nh-dialog-form>
    <template #footer>
      <div>
        <nh-button
          @click="handleClose"
        >
          关 闭
        </nh-button>
      </div>
    </template>
  </nh-dialog>
</template>
<script>

import { detailFileds } from '../fileds';

export default {

  name: 'StorePreview',
  emits: ['handlePreviewVisible'],
  data() {
    return {
      visible: false,
      detailFileds,
      previewName: {
        storageLocationCode: '库位号：',
        typeName: '货架类型：',
        warehouseAreaName: '所属库区：',
        col: '巷道：',
        layer: '层：',
        warehouseRow: '列：',
        warehouseLength: '限长(CM)：',
        warehouseWidth: '限宽(CM)：',
        warehouseHeight: '限高(CM)：',
        warehouseWeight: '限重(KG)：',
        trackOrderValue: '是否记录容器：',
        blendedGoodsValue: '是否混放SKU：',
        blendedBatchValue: '是否混放批次：',
        blendedShipperValue: '多货主混放：',
        containerOrder: '库位顺序号：',
        statusName: '库位状态：',
        goodsQualityName: '库存货品质量：',
      },
      previewValue: {},
    };
  },
  methods: {
    getLabel(val, arr) {
      const findArr = arr.find((item) => item.value === val);
      return findArr ? findArr.label : '';
    },
    open(data) {
      this.init(data);
      this.visible = true;
    },
    init(data) {
      if (data) {
        this.previewValue = data;
      } else {
        this.previewValue = {};
      }
    },
    handleClose() {
      this.visible = false;
    },
  },
};
</script>
