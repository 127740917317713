<template>
  <div id="PreviewList">
    <nh-dialog
      title="预览"
      :model-value="visible"
      append-to-body
      custom-class="listDialog custom-dialog_60"
    >
      <el-table
        :data="list"
        height="400"
        border
      >
        <el-table-column
          v-for="item in TABLECOLUMN"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :type="item.type"
        >
          <template #default="scope">
            <span v-if="item.filter">{{ filterLabel(scope.row[item.prop], item.arr) }}</span>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <span class="total">{{ `共计${list.length}个库位` }}</span>
      <template #footer>
        <span class="dialog-footer">
          <nh-button
            :loading="submitting"
            type="primary"
            @click="handleSubmit"
          >提 交</nh-button>
          <nh-button @click="handleClose">关 闭</nh-button>
        </span>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import TABLECOLUMN from './fileds';

export default {
  name: 'PreviewList',
  mixins: [loadingMixin],
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    submitting: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['handlePreviewListVisible', 'submit'],
  data() {
    return {
      visible: false,
      TABLECOLUMN,
      reservoirList: [],
      loading: {
        handleSubmit: false,
      },
    };
  },
  methods: {
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    handleSubmit() {
      this.$emit('submit');
    },
    handleClose() {
      this.close();
    },
    filterLabel(val, arr) {
      const filterArr = arr.find((item) => item.value === val);
      return filterArr ? filterArr.label : '';
    },
  },
};
</script>
<style lang="scss" scoped>
.listDialog {
  .total {
    float: right;
    line-height: 40px;
    margin-right: 20px;
    font-size: 13px;
  }
}
</style>
